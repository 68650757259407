const suite_faqs = [
  {
    id: 0,
    text: "Como gerenciar as permissões dos usuários no Conecta Suite",
    href: "https://conectasuite.com/perguntas-frequentes/conecta-suite/como-gerenciar-as-permissoes-dos-usuarios-no-conecta-suite/",
  },
  {
    id: 1,
    text: "Como consultar a fatura no Conecta Suite",
    href: "https://conectasuite.com/perguntas-frequentes/conecta-suite/como-consultar-a-fatura-no-conecta-suite/",
  },
  {
    id: 2,
    new: false,
    text: "Como aplicar a assinatura para uso em respostas e encaminhamentos?",
    href: "https://conectasuite.com/perguntas-frequentes/conecta-sign/como-aplicar-a-assinatura-para-uso-em-respostas-e-encaminhamentos/",
  },
  {
    id: 6,
    text: "Como o Conecta Suite ajudará a minha empresa",
    href: "https://conectasuite.com/perguntas-frequentes/conecta-suite/destaque/como-o-conecta-suite-ajudara-a-minha-empresa/",
  },
];
const sign_faqs = [
  {
    id: 3,
    text: "Como criar assinaturas de e-mail no Conecta Suite",
    href: "https://conectasuite.com/perguntas-frequentes/conecta-sign/como-criar-assinaturas-de-e-mail-no-conecta-sign/",
  },
  {
    id: 4,
    text: "Como usar informações na assinatura de e-mail",
    href: "https://conectasuite.com/perguntas-frequentes/conecta-sign/como-usar-tags-na-assinatura-de-e-mail/",
  },
  {
    id: 8,
    text: "Como editar Informações no CSV",
    href: "https://conectasuite.com/perguntas-frequentes/como-editar-tags-no-csv/",
  },
  {
    id: 12,
    text: "Como deixar seu domínio seguro no Google Workspace",
    href: "https://conectasuite.com/perguntas-frequentes/conecta-sign/como-deixar-seu-dominio-seguro-no-google-workspace/",
  },
];
const track_faqs = [
  // {
  //   id: 5,
  //   text: "Como forçar a instalação do Conecta Track para meus usuários",
  //   href: "https://conectasuite.com/perguntas-frequentes/conecta-track/como-forcar-a-instalacao-do-conecta-track-para-meus-usuarios/",
  // },
];
const control_faqs = [
  {
    id: 7,
    text: "Como utilizar o endereço de IP no Conecta Control",
    href: "https://conectasuite.com/perguntas-frequentes/conecta-control/endereco-de-ip-conecta-control/",
  },
  {
    id: 9,
    text: "Active Directory – Como o Conecta Control se comporta junto ao AD do Windows?",
    href: "https://conectasuite.com/perguntas-frequentes/conecta-control/active-directory-como-o-conecta-control-se-comparta-junto-ao-ad-do-windows/",
  },
  {
    id: 10,
    text: "Consigo ativar o controle de acesso apenas em alguns usuários?",
    href: "https://conectasuite.com/perguntas-frequentes/conecta-control/consigo-ativar-o-controle-de-acesso-apenas-em-alguns-usuarios/",
  },
  {
    id: 11,
    text: "Como o Conecta Control ajudará a minha empresa?",
    href: "https://conectasuite.com/perguntas-frequentes/conecta-control/como-o-conectacontrol-ajudara-a-minha-empresa/",
  },
];

export const faq_groups = [
  {
    text: "Suite",
    color: "primarySuite",
    product: "conecta_suite",
    faqs: suite_faqs,
  },
  {
    text: "Assinaturas",
    color: "primarySign",
    product: "conecta_sign",
    faqs: sign_faqs,
  },
  {
    text: "Control",
    color: "primaryControl",
    product: "conecta_control",
    faqs: control_faqs,
  },
  {
    text: "Track",
    color: "primaryTrack",
    product: "conecta_track",
    faqs: track_faqs,
  },
];
